.button-close {
    position: fixed;
    top: 30px;
    right: 30px;
    cursor: pointer;
    z-index: 5;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:before {
        content: '';
        position: absolute;
        background: #3a3a3a;
        width: 25px;
        height: 2px;
        transform: rotate(45deg);
    }
    &:after {
        content: '';
        position: absolute;
        background: #3a3a3a;
        width: 25px;
        height: 2px;
        transform: rotate(-45deg);
    }
}

.details-eye {
    position: absolute;
    bottom: 25px;
    right: 25px;
    width: 25px;
    cursor: pointer;
}

.scroll-to-top {
    position: fixed;
    left: 30px;
    bottom: 30px;
    width: 40px;
    height: 40px;
    background: white;
    border-radius: 50%;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

    &:before {
        content: '';
        position: absolute;
        background: #3a3a3a;
        width: 11px;
        height: 2px;
        transform: rotate(45deg);
        top: 48%;
        right: 27%;
    }
    &:after {
        content: '';
        position: absolute;
        background: #3a3a3a;
        width: 11px;
        height: 2px;
        transform: rotate(-45deg);
        top: 48%;
        left: 27%;
    }
}