$color-accent:blue;
$color-input-background: #f5f5f5;

$text-field-height: 56px;
$text-field-width: 300px;

.text-field--container {
  font-family: "Roboto", sans-serif;
  margin: 8px 4px;
  width: $text-field-width;
}

.text-field {
  background-color: $color-input-background;
  border-radius: 4px 4px 0 0;
  cursor: text;
  height: $text-field-height;
  overflow: hidden;
  position: relative;
  transition: background 166ms ease-in-out;
  user-select: none;
  width: 100%;

  .text-field--input,
  .text-field--label {
    font-size: 14px;
    height: 100%;
    line-height: $text-field-height;
    position: absolute;
    width: calc(100% - 24px);

    &:hover {
      background: darken($color-input-background, 3%);
    }
  }

  .text-field--input {
    appearance: none;
    background: transparent;
    border: none;
    color: #000000de;
    padding: 6px 12px;

    &:focus {
      outline: none;
    }

    &:focus + .text-field--label,
    &:not(:placeholder-shown) + .text-field--label {
      transform: scale(0.75) translateY(-5px);
    }
  }

  .text-field--label {
    color: #00000099;
    margin: 0 12px;
    pointer-events: none;
    transform-origin: 0 0;
    transition: transform 166ms ease-in-out;
  }

  .text-field--line,
  .text-field--line--color {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }

  .text-field--input:focus ~ .text-field--label {
    color: $color-accent;
  }

  .text-field--input:focus ~ .text-field--line--color {
    transform: scaleX(1);
  }
}




.pure-material-button-contained {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  padding: 0 16px;
  min-width: 64px;
  height: 36px;
  vertical-align: middle;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: uppercase;
  color: white;
  background-color: #333;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  font-size: 12px;
  font-weight: 500;
  line-height: 36px;
  overflow: hidden;
  outline: none;
  cursor: pointer;
  transition: box-shadow 0.2s;
  max-width: 100px;
  margin: 8px 4px;
  letter-spacing: .1rem;
}

.pure-material-button-contained::-moz-focus-inner {
  border: none;
}

/* Overlay */
.pure-material-button-contained::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #333;
  opacity: 0;
  transition: opacity 0.2s;
}

/* Ripple */
.pure-material-button-contained::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  padding: 50%;
  width: 32px; /* Safari */
  height: 32px; /* Safari */
  background-color: #333;
  opacity: 0;
  transform: translate(-50%, -50%) scale(1);
  transition: opacity 1s, transform 0.5s;
}

/* Hover, Focus */
.pure-material-button-contained:hover,
.pure-material-button-contained:focus {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.pure-material-button-contained:hover::before {
  opacity: 0.08;
}

.pure-material-button-contained:focus::before {
  opacity: 0.24;
}

.pure-material-button-contained:hover:focus::before {
  opacity: 0.3;
}

/* Active */
.pure-material-button-contained:active {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.pure-material-button-contained:active::after {
  opacity: 0.32;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0s;
}

/* Disabled */
.pure-material-button-contained:disabled {
  color: white;
  background-color: #333;
  box-shadow: none;
  cursor: initial;
}

.pure-material-button-contained:disabled::before {
  opacity: 0;
}

.pure-material-button-contained:disabled::after {
  opacity: 0;
}