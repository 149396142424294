.slide {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    .right {
        opacity: .8;
        height: 50px;
        width: 50px;
        background: url('../images/misc/chevron.svg');
        cursor: pointer;
        position: relative;
        right: -110px;
    }
    .left {
        opacity: .8;
        height: 50px;
        width: 50px;
        background: url('../images/misc/chevron.svg');
        transform: rotate(180deg);
        cursor: pointer;
        position: relative;
        left: -110px;
    }
}