.about {
    margin-top: 30vh;
    width: 83%;
    @media screen and (min-width: '1000px') {
        margin-top: 0;
        display: flex;
        justify-content: space-evenly;
        width: 70%;
    }
    &__image {
        width: 100%;
        max-width: 350px;
        @media screen and (min-width: '1000px') {
            width: auto;
            height: 70vh;
        }
    }
    &__content {
        max-width: 350px;
        text-align: left;
        display: flex;
        justify-content: center;
        flex-direction: column;
        @media screen and (max-width: '1000px') {
            margin-bottom: 20%;
        }
    }
    &__description {
        line-Height: 1.6;
        font-Size: 1.1rem;
    }
}