

.shop {
    cursor: pointer;
}

.tool-tip-coming-soon {
    width: 90px;
    display: flex;
    justify-content: center;
    visibility: hidden;
    background: #3a3a3a;
    padding: 5px 8px;
    color: white;
    border-radius: 3px;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    left: 72px;
    position: absolute;
}