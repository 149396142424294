.hide-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: white;
    z-index: 1;
    @media screen and (max-width: 1000px) {
        overflow-y: scroll;
    }
}

.horizontal-layout-alignment {
    height: 100vh;
    display: flex;
    align-items: center;
    z-index: 2;
    background: white;
    margin: -25px -30px -30px -30px;
    &::-webkit-scrollbar { 
        display: none; 
    }

    .image {
        height: 75vh;
        margin: 0 30px;
    }
}
    


