.hamburger {
    width: 30px;
    height: 30px;
    background: red;
    position: fixed;
    right: 30px;
    bottom: 30px;
    z-index: 3;
    cursor: pointer;
}
.navigation {
    display: block;
    z-index: 1;
    &__mobile {
        .sticky-wrapper {
            background: white;
            position: fixed;
            top: 0;
            right: -100%;
            width: 70%;
            height: 100%;
            padding-top: 50px;
            padding-left: 24px;
            transition: .2s ease;
            overflow-y: scroll;
        }
        .menu {
            position: fixed;
            top: 30px;
            right: 31px;
            z-index: 1;
            span {
                background: #333;
                border-radius: 10px;
                display: block;
                margin-bottom: 7px;
                height: 2px;
                width: 25px;
                &:nth-child(2) {
                    width: 15px;
                    margin-left: 10px;
                }
            }
            &.open-hamburger {
                span {
                    display: none;
                }
                position: fixed;
                top: 30px;
                right: 30px;
                cursor: pointer;
                z-index: 5;
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                &:before {
                    content: '';
                    position: absolute;
                    top: 7px;
                    background: #3a3a3a;
                    width: 25px;
                    height: 2px;
                    transform: rotate(45deg);
                }
                &:after {
                    content: '';
                    position: absolute;
                    top: 7px;
                    background: #3a3a3a;
                    width: 25px;
                    height: 2px;
                    transform: rotate(-45deg);
                }
                + .sticky-wrapper {
                    right: 0;
                    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
                }
            }
        }
        .anchors {
            margin-left: 24px;
        }
    }
    h2 {
        display: table;
        font-size: 1.6rem;
        margin: 12px 0px 20px;
        @media screen and (min-width: 1000px) {
            font-size: 1.5rem;
        }
        width: 100%;
    }
    .anchors {
        h4 {
            margin: 0;
            text-transform: capitalize;
            font-size: 1.2rem;
            @media screen and (min-width: 1000px) {
                font-size: 1rem;
            }
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 12px 0px 20px;
            @media screen and (min-width: 1000px) {
                margin: 6px 0px 20px;
            }
            li {
                color: rgba(0, 0, 0, .3);
                &.active {
                    color: red;
                }
                a {
                    display: inline-block;
                    font-size: 1.1rem;
                    line-height: 30px;
                    @media screen and (min-width: 1000px) {
                        font-size: 0.9rem;
                        line-height: 24px;
                    }
                    text-decoration: none;
                    text-transform: capitalize;
                    cursor: pointer;
    
    
                    &:hover {
                        color: #3a3a3a;
                    }
                } 
            } 
        }
    }
}